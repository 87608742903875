import { GetStaticPaths, GetStaticProps } from 'next';

import Page from '@/components/page';
import getPageStaticPaths from '@/lib/get-page-static-paths';
import getPageStaticProps from '@/lib/get-page-static-props';
import contentService from '@/middleware/content-service';
import mapToPageEntry from '@/middleware/mappers/pages/podcasts';

export const getStaticProps: GetStaticProps = getPageStaticProps({
  fetchPageFn: contentService.getPage(mapToPageEntry),
  getPageFullPath: ({ params }) => `/podcasts/${params?.slug}`,
});

export const getStaticPaths: GetStaticPaths = getPageStaticPaths;

export default Page;
